<template>
  <div>
    <v-overlay :value="overlay" z-index="999999">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-toolbar flat color="green" dark height="50">
      <v-radio-group v-model="panel" row hide-details @change="cambiarPanel()">
        <v-radio label="Estados" value="0">
          <template v-slot:label>
            <b class="white--text">Estados</b></template
          ></v-radio
        >
        <v-radio label="Tipo de estado" value="1">
          <template v-slot:label>
            <b class="white--text">Tipos de estado</b></template
          ></v-radio
        >
        <v-radio label="Cambios de estado" value="2">
          <template v-slot:label>
            <b class="white--text">Cambios de estado</b></template
          ></v-radio
        >
      </v-radio-group>
      {{ panel }}
    </v-toolbar>
    <v-card v-if="panel == 0">
      <v-toolbar flat>
        <v-icon left>mdi-list-status</v-icon>
        <v-toolbar-title>ESTADOS</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="abrirEstadoDialog(null)" color="blue" small dark
          ><v-icon>mdi-plus</v-icon> Crear</v-btn
        >

        <v-col cols="3">
          <v-autocomplete
            class="mt-4 ml-4"
            v-model="buscarEstadoPorTipo"
            label="Tipo de estado"
            :items="tiposEstado"
            item-text="description"
            item-value="status_type_id"
            clearable
            @change="cargarListaEstados()"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-text-field
            class="mt-3"
            v-model="buscarEstadoNombre"
            label="Nombre"
            @change="cargarListaEstados()"
          ></v-text-field>
        </v-col>
        <!-- <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="borrarEstado()" color="blue" small dark
          ><v-icon>mdi-delete</v-icon>Borrar</v-btn
        > -->
      </v-toolbar>
      <v-data-table
        :headers="headersEstados"
        :items-per-page="itemsPerPage"
        :items="estados"
        class="elevation-1"
        small
        item-key="status_id"
        hide-default-footer
      >
        <template v-slot:item.opciones="{ item }">
          <v-btn
            title="Editar estado"
            small
            text
            @click="abrirEstadoDialog(item.status_id)"
            dark
            ><v-icon small color="primary">mdi-pencil</v-icon></v-btn
          >

          <v-btn
            title="Borrar estado"
            small
            text
            @click="borrarEstado(item.status_id)"
            dark
            ><v-icon small color="red">mdi-delete</v-icon></v-btn
          >
        </template>
        <template v-slot:item.total_motivos="{ item }">
          <v-btn
            title="Motivos"
            small
            text
            @click="abrirMotivosDialog(item.status_id)"
            ><v-icon small color="blue">mdi-dots-horizontal</v-icon
            ><span v-if="item.total_motivos > 0"
              ><small
                ><b>{{ item.total_motivos }}</b>
              </small></span
            ></v-btn
          >
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <!-- PANEL TIPO ESTADO-->
    <v-card v-if="panel == 1">
      <v-toolbar flat>
        <v-icon left>mdi-list-status</v-icon>
        <v-toolbar-title>TIPOS DE ESTADO</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="abrirTipoEstadoDialog(null)" color="blue" small dark
          ><v-icon>mdi-plus</v-icon> Crear</v-btn
        >
      </v-toolbar>

      <v-data-table
        :headers="headersTipoEstado"
        :items-per-page="itemsPerPageTipoEstado"
        :items="listaTiposEstado"
        class="elevation-1"
        small
        item-key="status_type_id"
        hide-default-footer
      >
        <template v-slot:item.opciones="{ item }">
          <v-btn
            title="Editar tipo estado"
            small
            text
            @click="abrirTipoEstadoDialog(item.status_type_id)"
            dark
            ><v-icon small color="primary">mdi-pencil</v-icon></v-btn
          >

          <v-btn
            title="Borrar tipo estado"
            small
            text
            @click="borrarTipoEstado(item.status_type_id)"
            dark
            ><v-icon small color="red">mdi-delete</v-icon></v-btn
          >
        </template>
        <template v-slot:item.total_motivos="{ item }">
          <v-btn
            v-if="item.total_motivos > 0"
            title="Motivos"
            small
            text
            @click="abrirMotivosDialog(item.status_id)"
            ><v-icon small color="blue">mdi-dots-horizontal</v-icon
            >{{ item.total_motivos }}</v-btn
          >
        </template>
        <template v-slot:item.data_source_id="{ item }">
          <v-btn text small @click="abrirEstadoDialog(item.status_id)">
            {{ item.data_source_id }}
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPageTipoEstado"
            :length="pageCountTipoEstado"
            @input="handlePageChangeTipoEstado"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <!-- FIN PANEL TIPO ESTADO-->
    <!-- PANEL CAMBIOS DE ESTADO-->
    <v-card v-if="panel == 2">
      <v-toolbar flat>
        <v-icon left>mdi-list-status</v-icon>
        <v-toolbar-title>CAMBIOS DE ESTADO</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>

        <v-btn @click="abrirCambioEstadoDialog(null)" color="blue" small dark
          ><v-icon>mdi-plus</v-icon> Crear</v-btn
        >

        <v-divider class="mx-4" vertical></v-divider>
        <v-col cols="2">
          <v-autocomplete
            class="mt-4"
            v-model="statusIdFrom"
            label="Estado desde"
            :items="estadosDesde"
            item-text="description"
            item-value="status_id"
            clearable
            @change="cargarListaCambiosEstado()"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
            class="mt-4"
            v-model="statusIdTo"
            label="Estado hasta"
            :items="estadosHasta"
            item-text="description"
            item-value="status_id"
            clearable
            @change="cargarListaCambiosEstado()"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <v-autocomplete
            class="mt-4"
            v-model="buscarCambioEstadoStatusTypeId"
            label="Tipo de estado"
            :items="tiposEstado"
            item-text="description"
            item-value="status_type_id"
            clearable
            @change="cargarListaCambiosEstado()"
          ></v-autocomplete>
        </v-col>
      </v-toolbar>

      <v-data-table
        :headers="headersCambiosEstado"
        :items-per-page="itemsPerPageCambioEstado"
        :items="listaCambiosEstado"
        class="elevation-1"
        small
        item-key="status_type_id"
        hide-default-footer
      >
        <template v-slot:item.opciones="{ item }">
          <v-btn small icon @click="borrarCambioEstado(item)"
            ><v-icon small color="red">mdi-delete</v-icon></v-btn
          >
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPageCambioEstado"
            :length="pageCountCambioEstado"
            @input="handlePageChangeCambiosEstado"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <!-- DIALOGO ESTADO -->
    <v-dialog v-model="dialogoEstado" persistent width="700">
      <v-form ref="formEstado">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-list-status</v-icon>
            Estado
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoEstado = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosEstado.status_id"
                label="Codigo"
              ></v-text-field>
            </v-col>

            <v-col md="12" class="py-0 my-0">
              <v-select
                :rules="requiredRule"
                v-model="datosEstado.status_type_id"
                :items="tiposEstado"
                item-text="description"
                item-value="status_type_id"
                label="Tipo estado"
              ></v-select>
            </v-col>

            <v-col md="12" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosEstado.description"
                label="Descripción"
              ></v-text-field>
            </v-col>

            <v-col md="12" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosEstado.sequence_id"
                label="Secuencia"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-toolbar>
            <v-btn color="primary" small @click="guardarEstado">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogoEstado = false">
              <v-icon>mdi-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- DIALOGO TIPO DE ESTADO -->
    <v-dialog v-model="dialogoTipoEstado" persistent width="700">
      <v-form ref="formTipoEstado">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-list-status</v-icon>
            Tipo Estado
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoTipoEstado = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosTipoEstado.status_type_id"
                label="Codigo"
              ></v-text-field>
            </v-col>

            <v-col md="12" class="py-0 my-0">
              <v-select
                readonly
                v-model="datosTipoEstado.parent_type_id"
                :items="tiposEstadoPadre"
                item-text="description"
                item-value="status_type_id"
                label="Estado padre"
              ></v-select>
            </v-col>

            <v-col md="12" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosTipoEstado.description"
                label="Descripción"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-toolbar>
            <v-btn color="primary" small @click="guardarTipoEstado">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogoTipoEstado = false">
              <v-icon>mdi-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- DIALOGO CAMBIO ESTADO -->
    <v-dialog v-model="dialogoCambioEstado" persistent width="700">
      <v-form ref="formCambioEstado">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-list-status</v-icon>
            Cambio de estado válido
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoCambioEstado = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
              <v-select
                v-model="datosCambioEstado.status_id"
                :items="estadosDesde"
                item-text="description"
                item-value="status_id"
                label="Estado desde"
              ></v-select>
            </v-col>

            <v-col md="12" class="py-0 my-0">
              <v-select
                v-model="datosCambioEstado.status_id_to"
                :items="estadosHasta"
                item-text="description"
                item-value="status_id"
                label="Estado hacia"
              ></v-select>
            </v-col>

            <v-col md="12" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosCambioEstado.transition_name"
                label="Descripción"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-toolbar>
            <v-btn color="primary" small @click="guardarCambioEstado">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogoCambioEstado = false">
              <v-icon>mdi-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- DIALOGO MOTIVOS ESTADO -->
    <v-dialog v-model="dialogoMotivoEstado" persistent width="700">
      <v-form ref="formEstado">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-list-status</v-icon>
            Motivos
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoMotivoEstado = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-row>
            <v-col cols="12">
              <v-data-table
                :items="listaMotivos"
                :headers="headersMotivos"
                hide-default-footer
              >
                <template v-slot:item.opciones="{ item }">
                  <v-btn
                    small
                    icon
                    color="red"
                    @click="borrarMotivo(item.wes_motivo_id)"
                    ><v-icon small>mdi-delete</v-icon></v-btn
                  >

                  <v-btn
                    small
                    icon
                    color="green"
                    @click="editarMotivo(item.wes_motivo_id)"
                    ><v-icon small>mdi-pencil</v-icon></v-btn
                  >
                </template>

                <template v-slot:item.activo="{ item }">
                  <span v-if="item.activo == true">SI</span>
                  <span v-if="item.activo == false">NO</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :color="item.activo == true ? 'green' : 'grey'"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarEstadoMotivo(item)"
                        >{{ getIcono(item) }}
                      </v-icon>
                      <!-- <v-icon
                        v-if="item.activo == false"
                        color="gray"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="activarEstadoMotivo(item)"
                      >
                        mdi-toggle-switch-off
                      </v-icon> -->
                    </template>
                    <span>Cambiar estado</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="4" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosMotivoEstado.wes_motivo_id"
                label="Codigo"
              ></v-text-field>
            </v-col>

            <v-col md="5" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosMotivoEstado.descripcion"
                label="Descripción"
              ></v-text-field>
            </v-col>

            <v-col md="2" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosMotivoEstado.secuencia"
                label="Secuencia"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-toolbar>
            <v-btn color="primary" small @click="guardarMotivoEstado">
              <v-icon small left>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogoMotivoEstado = false">
              <v-icon small left>mdi-cancel</v-icon>Salir
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "EstadosEvento",
  data: () => ({
    show: false,
    overlay: false,
    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    //totalItems: 1,

    currentPage: 1,
    itemsPerPage: 10,
    pageCount: 0,
    iconot: "mdi-toggle-switch",
    colorIcono: "primary",
    currentPageTipoEstado: 1,
    itemsPerPageTipoEstado: 10,
    pageCountTipoEstado: 0,

    currentPageCambioEstado: 1,
    itemsPerPageCambioEstado: 10,
    pageCountCambioEstado: 0,

    required: [(v) => !!v || "El campo es requerido"],

    headersEstados: [
      { text: "Opciones", align: "start", value: "opciones", width: "15%" },
      { text: "Motivos", align: "start", value: "total_motivos", width: "5%" },
      { text: "Código", align: "start", value: "status_id", width: "15%" },
      { text: "Tipo", align: "start", value: "tipo_estado", width: "20%" },
      { text: "Nombre", align: "start", value: "description", width: "35%" },
      { text: "Secuencia", align: "start", value: "sequence_id", width: "10%" },
    ],
    panel: "",
    estados: [],
    dialogoEstado: false,
    datosEstado: {
      status_id: "",
      status_type_id: "",
      description: "",
    },

    tiposEstado: [],
    tiposEstadoPadre: [],

    headersTipoEstado: [
      { text: "Opciones", value: "opciones", align: "start" },
      { text: "Codigo", value: "status_type_id", align: "start" },
      { text: "Nombre", value: "description", align: "start" },
      { text: "Tipo estado padre", value: "parent_status", align: "start" },
    ],

    listaTiposEstado: [],

    headersMotivos: [
      { text: "Opciones", value: "opciones", align: "start" },
      { text: "Codigo", value: "wes_motivo_id", align: "start" },
      { text: "Nombre", value: "descripcion", align: "start" },
      { text: "Secuencia", value: "secuencia", align: "start" },
      { text: "Activo", value: "activo", align: "start" },
    ],

    listaMotivos: [],

    headersCambiosEstado: [
      { text: "Opciones", value: "opciones", align: "start" },
      { text: "Estado desde", value: "estado_desde", align: "start" },
      { text: "Estado hasta", value: "estado_hasta", align: "start" },
      { text: "Descripcion", value: "transition_name", align: "start" },
    ],
    listaCambiosEstado: [],
    statusValidChange: [],
    crearEstado: false,
    dialogoTipoEstado: false,
    dialogoMotivoEstado: false,
    datosTipoEstado: {},
    datosMotivoEstado: {},
    crearEstadoMotivo: true,

    estadosDesde: [],
    estadosHasta: [],
    dialogoCambioEstado: false,
    datosCambioEstado: {
      status_id: "",
      status_id_to: "",
      transition_name: "",
    },
    statusIdFrom: "",
    statusIdTo: "",

    statusMotivoId: "",

    buscarCambioEstadoStatusTypeId: "",
    buscarEstadoNombre: "",
    buscarEstadoPorTipo: "",
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),
    getIcono(item) {
      if (item.activo == true) {
        return "mdi-toggle-switch";
      } else {
        return "mdi-toggle-switch-off";
      }
    },

    cargarDatos() {
      this.setLoadingTable(true);

      this.setUrl("estado-evento");

      this.requestApi({
        method: "GET",
        data: {
          tipo_lista: "datosEstados",
        },
      })
        .then((res) => {
          this.tiposEstado = res.data.detail.tipos_estado;
          this.tiposEstadoPadre = res.data.detail.tipos_estado_padre;
          this.estadosDesde = res.data.detail.estados;
          this.estadosHasta = res.data.detail.estados;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarListaEstados() {
      this.setLoadingTable(true);

      this.setUrl("estado-evento");

      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          page_size: this.itemsPerPage,
          paginator: true,
          status_type_id: this.buscarEstadoPorTipo,
          tipo_lista: "listaEstado",
          status_description: this.buscarEstadoNombre,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.estados = res.data._embedded.estado_evento;
          this.pageCount = res.data.page_count;
          this.currentPage = res.data.page;
          this.totalItems = res.data.total_items;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarListaTiposEstados() {
      this.setLoadingTable(true);
      this.setUrl("estado-evento");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPageTipoEstado,
          page_size: this.itemsPerPageTipoEstado,
          paginator: true,

          tipo_lista: "listaTiposEstado",
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.listaTiposEstado = res.data._embedded.estado_evento;
          this.pageCountTipoEstado = res.data.page_count;
          this.currentPageTipoEstado = res.data.page;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    abrirEstadoDialog(estadoId) {
      this.dialogoEstado = true;
      this.datosEstado = {
        status_id: "",
        status_type_id: "",
        description: "",
      };
      this.crearEstado = true;
      if (estadoId != null) {
        this.crearEstado = false;
        this.cargarDatosEstado(estadoId);
      }
    },

    cargarDatosEstado(id) {
      this.setLoadingTable(true);
      this.setUrl("estado-evento/" + id);
      this.requestApi({
        method: "GET",
        data: { tipo_dato: "datosEstado" },
      })
        .then((res) => {
          console.log(res.data);
          this.datosEstado = res.data.detail;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarEstado() {
      if (!this.$refs.formEstado.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("estado-evento");
      this.requestApi({
        method: "POST",
        data: {
          accion: this.crearEstado == true ? "crearEstado" : "modificarEstado",
          datos_estado: this.datosEstado,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.cargarListaEstados();
          this.dialogoEstado = false;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    borrarEstado(estadoId) {
      Vue.swal({
        html: "Está seguro de eliminar este estado ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.setLoadingTable(true);
          this.setUrl("estado-evento");
          this.requestApi({
            method: "DELETE",
            data: {
              accion: "borrarEstado",
              status_id: estadoId,
            },
          })
            .then((res) => {
              //console.log(res.data);
              this.cargarListaEstados();
              this.dialogoEstado = false;
            })
            .then(() => {
              this.setLoadingTable(false);
            });
        }
      });
    },

    abrirTipoEstadoDialog(tipoEstadoId) {
      this.dialogoTipoEstado = true;
      this.datosTipoEstado = {
        status_type_id: "",
        parent_type_id: "CALENDAR_STATUS",
        description: "",
      };
      this.crearTipoEstado = true;
      if (tipoEstadoId != null) {
        this.crearTipoEstado = false;
        this.cargarDatosTipoEstado(tipoEstadoId);
      }
    },
    cargarDatosTipoEstado(id) {
      this.setLoadingTable(true);
      this.setUrl("estado-evento/" + id);
      this.requestApi({
        method: "GET",
        data: { tipo_dato: "datosTipoEstado" },
      })
        .then((res) => {
          console.log(res.data);
          this.datosTipoEstado = res.data.detail;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarTipoEstado() {
      if (!this.$refs.formTipoEstado.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("estado-evento");
      this.requestApi({
        method: "POST",
        data: {
          accion:
            this.crearTipoEstado == true
              ? "crearTipoEstado"
              : "modificarTipoEstado",
          datos_tipo_estado: this.datosTipoEstado,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.cargarListaTiposEstados();
          this.cargarDatos();
          this.dialogoTipoEstado = false;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    borrarTipoEstado(tipoEstadoId) {
      Vue.swal({
        html: "Está seguro de eliminar este tipo de estado ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        this.setLoadingTable(true);
        this.setUrl("estado-evento");
        this.requestApi({
          method: "DELETE",
          data: {
            accion: "borrarTipoEstado",
            status_type_id: tipoEstadoId,
          },
        })
          .then((res) => {
            //console.log(res.data);
            this.cargarListaTiposEstados();
            this.cargarDatos();
            this.dialogoTipoEstado = false;
          })
          .then(() => {
            this.setLoadingTable(false);
          })
          .finally(() => {
            this.setLoadingTable(false);
          });
      });
    },
    cargarListaMotivos(status_id) {
      this.setLoadingTable(true);
      this.setUrl("estado-evento");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPageTipoEstado,
          page_size: this.itemsPerPageTipoEstado,
          paginator: true,
          tipo_lista: "listaMotivos",
          work_effort_status_id: status_id,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.listaMotivos = res.data._embedded.estado_evento;
          //this.pageCountTipoEstado = res.data.page_count;
          //this.currentPageTipoEstado = res.data.page;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    abrirMotivosDialog(statusId) {
      this.statusMotivoId = statusId;
      this.datosMotivoEstado = {
        wes_motivo_id: null,
        descripcion: "",
        work_effort_status_id: statusId,
        activo: true,
        secuencia: "",
      };
      this.crearEstadoMotivo = true;
      this.dialogoMotivoEstado = true;
      this.cargarListaMotivos(statusId);
    },
    editarMotivo(wes_motivo_id) {
      this.crearEstadoMotivo = false;
      this.cargarDatosMotivo(wes_motivo_id);
    },
    cargarDatosMotivo(id) {
      this.setLoadingTable(true);
      this.setUrl("estado-evento/" + id);
      this.requestApi({
        method: "GET",
        data: { tipo_dato: "datosEstadoMotivo" },
      })
        .then((res) => {
          this.datosMotivoEstado = res.data.detail;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarMotivoEstado() {
      if (!this.$refs.formEstado.validate()) {
        return false;
      }
      this.setLoadingTable(true);
      this.setUrl("estado-evento");
      this.requestApi({
        method: "POST",
        data: {
          accion:
            this.crearEstadoMotivo == true
              ? "crearEstadoMotivo"
              : "modificarEstadoMotivo",
          datos_motivo: this.datosMotivoEstado,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.cargarListaEstados();
          this.cargarListaMotivos(this.statusMotivoId);
          this.datosMotivoEstado = {
            wes_motivo_id: null,
            descripcion: "",
            activo: true,
            secuencia: "",
          };
          this.crearEstadoMotivo = true;
          this.dialogoEstado = false;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    activarEstadoMotivo(item) {
      let datosMotivo = {};
      datosMotivo.wes_motivo_id = item.wes_motivo_id;
      datosMotivo.descripcion = item.descripcion;
      datosMotivo.work_effort_status_id = item.work_effort_status_id;
      datosMotivo.secuencia = item.secuencia;
      datosMotivo.activo = !item.activo;

      this.setLoadingTable(true);
      this.setUrl("estado-evento");
      this.requestApi({
        method: "POST",
        data: {
          accion: "modificarEstadoMotivo",
          datos_motivo: datosMotivo,
        },
      })
        .then((res) => {
          //console.log(res);
          this.cargarListaMotivos(this.statusMotivoId);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    handlePageChange(value) {
      this.cargarListaEstados();
    },
    handlePageChangeTipoEstado(value) {
      this.cargarListaTiposEstados();
    },

    cargarListaCambiosEstado() {
      this.setLoadingTable(true);
      this.setUrl("estado-evento");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPageCambioEstado,
          page_size: this.itemsPerPageCambioEstado,
          paginator: true,
          tipo_lista: "listaCambiosEstado",
          status_id: this.statusIdFrom,
          status_id_to: this.statusIdTo,
          status_type_id: this.buscarCambioEstadoStatusTypeId,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.listaCambiosEstado = res.data._embedded.estado_evento;
          this.pageCountCambioEstado = res.data.page_count;
          //this.currentPageCambioEstado = res.data.page;
          //this.totalItemsCambiosEstado = res.data.total_items;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    handlePageChangeCambiosEstado(value) {
      this.cargarListaCambiosEstado();
    },
    cambiarPanel() {
      if (this.panel == 0) {
        this.cargarListaEstados();
      }
      if (this.panel == 1) {
        this.cargarListaTiposEstados();
      }
      if (this.panel == 2) {
        this.cargarListaCambiosEstado();
      }
    },
    borrarMotivo(motivoId) {
      Vue.swal({
        html: "Está seguro de eliminar este motivo ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.setLoadingTable(true);
          this.setUrl("estado-evento");
          this.requestApi({
            method: "DELETE",
            data: {
              accion: "borrarEstadoMotivo",
              wes_motivo_id: motivoId,
            },
          })
            .then((res) => {
              //console.log(res.data);
              this.cargarListaEstados();
              this.cargarListaMotivos(this.statusMotivoId);
              this.dialogoEstado = false;
            })
            .then(() => {
              this.setLoadingTable(false);
            });
        }
      });
    },

    abrirCambioEstadoDialog(id) {
      this.dialogoCambioEstado = true;
      this.datosCambioEstado = {};
    },

    guardarCambioEstado() {
      this.setLoadingTable(true);
      this.setUrl("estado-evento");
      this.requestApi({
        method: "POST",
        data: {
          accion: "crearCambioEstado",
          cambio_estado: this.datosCambioEstado,
        },
      })
        .then((res) => {
          //console.log(res);
          this.dialogoCambioEstado = false;
          this.cargarListaCambiosEstado();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    borrarCambioEstado(item) {
      Vue.swal({
        html: "Está seguro de eliminar este cambio de estado ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.setLoadingTable(true);
          this.setUrl("estado-evento");
          this.requestApi({
            method: "DELETE",
            data: {
              accion: "borrarCambioEstado",
              cambio_estado: item,
            },
          })
            .then((res) => {
              this.cargarListaCambiosEstado();
            })
            .then(() => {
              this.setLoadingTable(false);
            });
        }
      });
    },
  },
  mounted() {
    this.cargarDatos();
    this.setTitleToolbar("ESTADOS");
    this.panel = 0;
    this.cargarListaEstados();
  },
};
</script>
